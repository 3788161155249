import React, { Component } from 'react';
// Styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/facilities.css';
// Components
import Search from './Search';
import FacilityModal from './FacilityModal';
import EditFacilityModal from './EditFacilityModal';
// Services
import { apiConfig } from '../services/apiConfig';
import { apiAuthentication } from '../services/Authenticate';
// Others
import { withRouter } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Modal, Toast, Spinner } from 'react-bootstrap';

class Facilities extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      title: 'FACILITY MANAGEMENT',
      loading: true,
      facilities: [],
      searchResults: [],
      showAddFacilityModal: false,
      showEditFacilityModal: false,
      showDeviceListModal: false,
      showErrorModal: false,
      errorModalMessage: '',
      showDeleteFacilityModal: false,
      selectedFacilityName: null,
      selectedFacilityNumber: null,
      selectedFacility: null,
      devicesUpdatedAt: '',
      facilityDevices: [],
      showToast: false,
      toastType: '',
      toastMessage: '',
    };
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.getFacilities();
  }

  componentWillUnmount() { }

  formatDate = (cell, _row) => {
    const date = new Date(cell);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${(hours % 12) || 12}:${minutes} ${amOrPm}`;
    const formattedDate = `${month}-${day}-${year} ${formattedTime}`;
    return formattedDate;
  }

  getSearchParams() {
    const params = new URLSearchParams(this.props.location.search);
    const searchCriteria = params.get('searchBy') || '';
    const searchString = params.get('query') || '';
    if (searchCriteria && searchString) {
      this.handleSearch({ searchCriteria, searchString });
    }
}

  async getFacilities() {
    return new Promise((resolve, reject) => {
      const apiUrl = apiConfig.baseUrl + apiConfig.apis.facility + '?type=facility';
      const authHeader = apiAuthentication.getCookie().header;
      fetch(apiUrl, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data && data.status === 200 && data.data) {
            this.setState({
              loading: false,
              facilities: data.data,
              searchResults: data.data,
            });
            resolve(data.data);
          } else {
            console.error('Invalid or missing data format:', data);
            reject('Invalid or missing data format');
          }
        })
        .catch(error => {
          console.error('Error fetching facilities:', error);
          reject(error);
        });
    });
  }

  handleSearch = ({searchCriteria, searchString}) => {
    const { facilities } = this.state;

    let searchResults = [];

    if(searchCriteria === 'start_ip'){
      searchResults = facilities.filter(facility =>
        facility[searchCriteria]?.toLowerCase().startsWith(searchString.toLowerCase())
      );
    }else{
      searchResults = facilities.filter(facility =>
        facility[searchCriteria]?.toLowerCase().includes(searchString.toLowerCase())
      );
    }

    const queryString = `?searchBy=${searchCriteria}&query=${searchString}`;
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString,
    });
    this.setState({ searchResults });
  };

  handleClearSearch = () => {
    this.props.history.replace(this.props.location.pathname);
    this.setState({ searchResults: this.state.facilities });
  };

  getDevices(facilityName) {
    const apiUrl = `${apiConfig.apiUrl}/helper/deviceslist/${facilityName}`
    const authHeader = apiAuthentication.getCookie().header;
    fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.status === 200 && data.data) {
          if (data.deviceStatsLastFetchDateTime) {
            const date = new Date(data.deviceStatsLastFetchDateTime);
            const devicesUpdatedAt = date.toLocaleString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });
            this.setState({devicesUpdatedAt});
          }
          this.setState({facilityDevices: data.data});
        } else {
          console.error('Invalid or missing data format:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching devices:', error);
      });
  }

  openAddFacilityModal = () => {
    this.setState({
      showAddFacilityModal: true,
    });
  }

  closeAddFacilityModal = () => {
    this.setState({
      showAddFacilityModal: false,
    });
  }

  onCancelAddFacility = () => {
    this.setState({
      showAddFacilityModal: false,
    });
  }

  openEditFacilityModal = (facility) => {
    this.setState({
      showEditFacilityModal: true,
      selectedFacility: facility,
    });
  }

  closeEditFacilityModal = () => {
    this.setState({
      showEditFacilityModal: false,
      selectedFacility: null,
    });
  }

  onCancelEditFacility = () => {
    this.setState({
      showEditFacilityModal: false,
      selectedFacility: null,
    });
  }

  handleShowToast = (message) => {
    this.setState({
      showToast: true,
      toastMessage: message,
    });
  };

  handleAddFacility = async (facilityData) => {
    const { number } = facilityData;
    this.setState({
      showAddFacilityModal: false,
      toastType: 'add'
    });
    this.handleShowToast(`Facility ${number} has been successfully added.`);
    await this.getFacilities();
    this.getSearchParams();
  }

  handleEditFacility = async (facilityData) => {
    const { number } = facilityData;
    this.setState({
      showEditFacilityModal: false,
      selectedFacility: null,
      toastType: 'add'
    });
    this.handleShowToast(`Facility ${number} has been successfully edited.`);
    await this.getFacilities();
    this.getSearchParams();
  }

  handleRemoveFacility = ({ name, number }) => {
    this.setState({
      showDeleteFacilityModal: true,
      selectedFacilityName: name,
      selectedFacilityNumber: number,
    });
  }

  closeDeleteFacilityModal = () => {
    this.setState({
      showDeleteFacilityModal: false,
      selectedFacilityName: null,
      selectedFacilityNumber: null,
    });
  }

  confirmDeleteFacility = () => {
    const { selectedFacilityName: name, selectedFacilityNumber: number } = this.state;
    const apiUrl = `${apiConfig.baseUrl}${apiConfig.apis.facility}/delete`;
    const authHeader = apiAuthentication.getCookie().header;
    fetch(apiUrl, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
      body: JSON.stringify({
        name,
      })
    })
      .then(response => response.json())
      .then(async (data) => {
        console.log('Deleted facility:', data);
        if (data && data.status === 200 && data.message) {
          this.setState({
            showDeleteFacilityModal: false,
            selectedFacilityName: null,
            selectedFacilityNumber: null,
            toastType: 'delete',
          });
          this.handleShowToast(`Facility ${number} has been successfully deleted.`);
          await this.getFacilities();
          this.getSearchParams();
        } else if (data && data.message) {
          console.error('Invalid or missing data format:', data);
          this.setState({
            showErrorModal: true,
            errorModalMessage: data.message,
          });
        } else {
          console.error('Unhandled error message:', data);
        }
      })
      .catch(error => {
        console.error('Error deleting facility:', error);
      })
      .finally(() => {
        this.setState({
          showDeleteFacilityModal: false,
          selectedFacilityName: null,
          selectedFacilityNumber: null,
        })
      });
  }

  handleErrorClose = () => {
    this.setState({
      showErrorModal: false,
      errorModalMessage: '',
    });
  };

  openDeviceListModal = (deviceCount, facility) => {
    this.getDevices(facility.name);
    this.setState({
      showDeviceListModal: true,
      modalDeviceCount: deviceCount,
      selectedFacilityName: facility.station_type,
      selectedFacilityNumber: facility.number
    });
  }

  closeDeviceListModal = () => {
    this.setState({
      showDeviceListModal: false,
      modalDeviceCount: null,
      selectedFacilityName: null,
      selectedFacilityNumber: null,
      devicesUpdatedAt: '',
      facilityDevices: [],
    });
  }

  render() {
    const rowStyle = (row, rowIndex) => ({
      backgroundColor: row.parent_id ? '#f2f2f2' : 'white'
    });
    const facilityColumns = [
      {
        dataField: 'station_type',
        text: 'Facility Name',
        sort: true,
        headerStyle: {
          width: '15%'
        },
        style: {
          width: '15%'
        }
      },
      {
        dataField: 'name',
        text: 'Abbr',
        sort: true,
      },
      {
        dataField: 'number',
        text: 'Facility #',
        sort: true,
        sortFunc: (a, b, order) => order === 'asc' ? a - b : b - a,
      },
      {
        dataField: 'region',
        text: 'Region',
        sort: true
      },
      {
        dataField: 'district',
        text: 'District',
        sort: true,
        headerStyle: {
          width: '16%'
        },
      },
      {
        dataField: 'address',
        text: 'Address',
        headerStyle: {
          width: '28%'
        },
        style: {
          width: '15%'
        }
      },
      {
        dataField: 'start_ip',
        text: 'Start IP',
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            const ipA = a.split('.').map(Number);
            const ipB = b.split('.').map(Number);
            for (let i = 0; i < 4; i++) {
              if (ipA[i] < ipB[i]) return -1;
              if (ipA[i] > ipB[i]) return 1;
            }
            return 0;
          } else {
            const ipA = a.split('.').map(Number);
            const ipB = b.split('.').map(Number);
            for (let i = 0; i < 4; i++) {
              if (ipA[i] > ipB[i]) return -1;
              if (ipA[i] < ipB[i]) return 1;
            }
            return 0;
          }
        },
      },
      {
        dataField: 'end_ip',
        text: 'End IP',
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            const ipA = a.split('.').map(Number);
            const ipB = b.split('.').map(Number);
            for (let i = 0; i < 4; i++) {
              if (ipA[i] < ipB[i]) return -1;
              if (ipA[i] > ipB[i]) return 1;
            }
            return 0;
          } else {
            const ipA = a.split('.').map(Number);
            const ipB = b.split('.').map(Number);
            for (let i = 0; i < 4; i++) {
              if (ipA[i] > ipB[i]) return -1;
              if (ipA[i] < ipB[i]) return 1;
            }
            return 0;
          }
        },
      },
      {
        dataField: 'device_count',
        text: 'Devices',
        sort: true,
        sortFunc: (a, b, order) => order === 'asc' ? a - b : b - a,
        formatter: (cell, row) => {
          if (!isNaN(cell)) {
            return (
              <button className='device-count' onClick={() => this.openDeviceListModal(cell, row)}>{cell}</button>
            );
          } else {
            return null;
          }
        },
        headerAlign: 'center',
        style: {
          textAlign: 'center',
          fontWeight: 'bold',
        },
      },
      {
        dataField: '',
        text: 'Actions',
        classes: 'table-actions',
        formatter: (_cell, row) => {
          const { name, number } = row;
          return (
            <div style={{minWidth : 45} }>
              <button className='delete-button'
                onClick={() => this.handleRemoveFacility({ name, number })}>
                <i className='fa fa-trash'></i>
              </button>
              <button className='edit-button'
                onClick={() => this.openEditFacilityModal(row)}>
                <i className='fa fa-pencil'></i>
              </button>
            </div>
          );
        },
      }
    ];
    const selectedOptions = [
      { value: 'station_type', label: 'Facility Name', placeholder: 'Facility Name'},
      { value: 'name', label: 'Facility Abbr', placeholder: 'Facility Abbreviation'},
      { value: 'number', label: 'Facility #', placeholder: 'Facility Number'},
      { value: 'region', label: 'Region', placeholder: 'Facility Region'},
      { value: 'district', label: 'District', placeholder: 'Facility District'},
      { value: 'address', label: 'Address', placeholder: 'Facility Address'},
      { value: 'start_ip', label: 'IP Address', placeholder: 'Facility IP Address'}
    ];
    const deviceColumns = [
      {
        dataField: 'model',
        text: 'Model #'
      },
      {
        dataField: 'sn',
        text: 'Serial #',
        headerStyle: {
          width: '25%'
        },
        style: {
          width: '40%'
        }
      },
      {
        dataField: 'ipaddress',
        text: 'IP Address ',
        headerStyle: {
          width: '20%'
        }
      },
      {
        dataField: 'alert_version',
        text: 'Version',
        headerStyle: {
          width: '14%'
        },
      },
      {
        dataField: 'last_seen_time',
        text: 'Last Seen',
        formatter: this.formatDate,
        headerStyle: {
          width: '28%'
        }
      }
    ];
    return (
      <div className='container-fluid d-flex flex-column Container'>
        <div className='row'>
          <div className='col-12'>
            <div className='h-100 card d-flex flex-column facility-container'>
              <div className='border-bottom card-header'>
                <h5 className='m-0 facility-title'>{this.state.title}</h5>
              </div>
              <div className='search-row'>
                <Search
                  defaultSearchCriteria='station_type'
                  defaultsearchPlaceholder='Search for a Facility Name'
                  selectOptions={selectedOptions}
                  onSearch={this.handleSearch}
                  onClear={this.handleClearSearch}
                ></Search>
                <span></span>
                <button className='add-button' onClick={this.openAddFacilityModal}><i className='fa fa-plus'></i>&nbsp; &nbsp; ADD NEW</button>
              </div>
              <div className='table-container flex-grow-1'>
                {this.state.loading ?
                <div className='loading'>
                <Spinner animation="border" variant="secondary" size="sm" /> 
                <a>Loading...</a>
                 </div> :
                  <div className='table-wrapper'>
                    <BootstrapTable
                      keyField='number'
                      data={this.state.searchResults}
                      columns={facilityColumns}
                      bootstrap4
                      bordered={false}
                      defaultSorted={[{ dataField: 'facilityName', order: 'asc' }]}
                      headerClasses='thead-light'
                      rowStyle={rowStyle}
                      noDataIndication='No results found'
                    // wrapperClasses='table-responsive'
                    />
                  </div> 
                }
              </div>
              {this.state.showDeviceListModal ? (<Modal show={this.state.showDeviceListModal} size="md" centered className='device-list-modal'>
                <Modal.Header>
                  <Modal.Title>
                    {this.state.modalDeviceCount} DEVICES IN {this.state.selectedFacilityName} (FACILITY {this.state.selectedFacilityNumber})
                  </Modal.Title>
                  <div className='device-list-exit' onClick={this.closeDeviceListModal}>X</div>
                </Modal.Header>
                {this.state.devicesUpdatedAt && <p className='device-updated'>Updated {this.state.devicesUpdatedAt}</p>}
                <Modal.Body>
                  <BootstrapTable
                    keyField='udi'
                    data={this.state.facilityDevices}
                    columns={deviceColumns}
                    bootstrap4
                    bordered={false}
                    wrapperClasses='devices-table'
                    headerClasses='thead-light'
                  />
                </Modal.Body>
              </Modal>) : null}
              {this.state.showAddFacilityModal &&
                <FacilityModal
                  mode='add'
                  show={this.state.showAddFacilityModal}
                  onClose={this.closeAddFacilityModal}
                  onCancelFacilityAction={this.closeAddFacilityModal}
                  onFacilityAction={this.handleAddFacility}
                />
              }
              {this.state.showEditFacilityModal &&
                <EditFacilityModal
                  show={this.state.showEditFacilityModal}
                  facility={this.state.selectedFacility}
                  onClose={this.closeEditFacilityModal}
                  onCancelEditFacility={this.closeEditFacilityModal}
                  onEditFacility={this.handleEditFacility}
                />
              }
              {this.state.showDeleteFacilityModal ? (<Modal show={this.state.showDeleteFacilityModal} size='md' centered className='delete-facility-modal'>
                <Modal.Header>
                  <Modal.Title className='uppercase'>Confirm delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className='delete-main uppercase'>Are you sure you want to delete facility {this.state.selectedFacilityNumber}?</p>
                  <p className='uppercase'>This can't be undone</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary uppercase'
                    onClick={this.closeDeleteFacilityModal}
                  >
                    Cancel
                  </Button>
                  <span></span>
                  <Button
                    variant='success uppercase'
                    onClick={this.confirmDeleteFacility}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>) : null}
              <Toast
                className={`toast-container toast-${this.state.toastType}`}
                animation
                autohide
                show={this.state.showToast}
                delay={5000}
                onClose={() => this.setState({
                  showToast: false,
                  toastType: '',
                  toastMessage: '',
                })}>
                <Toast.Body>{this.state.toastMessage}</Toast.Body>
              </Toast>
              {this.state.showErrorModal ? (<Modal show={this.state.showErrorModal} size='md' centered className='delete-facility-error-modal'>
                <Modal.Header>
                  <Modal.Title className='uppercase'>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className='error-main uppercase'>{this.state.errorModalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary' className='btn uppercase' onClick={this.handleErrorClose}>Ok</Button>
                </Modal.Footer>
              </Modal>) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Facilities);
