import React, { Component, createRef } from 'react';
// Styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/search.css';

class Search extends Component {
  constructor(props) {
    super(props);
    const { defaultSearchCriteria, defaultsearchPlaceholder } = props;
    const initialState = {
      loading: false,
      searchCriteria: defaultSearchCriteria,
      isInputDisabled: false,
      searchPlaceholder: defaultsearchPlaceholder,
      searchString: '',
      isSearchDisabled: true,
      showSearchClear: false,
    };
    this.state = { ...initialState };
    this.searchStringRef = createRef();
  }

  handleSearchCriteriaChange = event => {
    event.preventDefault();
    const selectedCriteria = event.target.value;
    const selectedOption = this.props.selectOptions.find(option => option.value === selectedCriteria);
    let searchPlaceholder = `Search for a ${selectedOption.placeholder}`;
    this.setState({
      searchCriteria: selectedCriteria,
      searchPlaceholder,
    });
  };

  handleInputChange = () => {
    const isSearchDisabled = this.searchStringRef.current.value.trim() === '';
    this.setState({ isSearchDisabled });
  };

  handleSearch = () => {
    const { searchCriteria } = this.state;
    const searchString = this.searchStringRef.current.value;
    this.props.onSearch({ searchCriteria, searchString });
    this.setState({
      searchString,
      isInputDisabled: true,
      showSearchClear: true,
    });
  };

  handleClearSearch = () => {
    this.props.onClear();
    this.searchStringRef.current.value = '';
    this.setState({
      searchString: '',
      isInputDisabled: false,
      isSearchDisabled: true,
      showSearchClear: false,
    });
  };

  render() {
    const { selectOptions } = this.props;
    const { 
      searchCriteria, 
      searchPlaceholder, 
      showSearchClear, 
      searchString, 
      isSearchDisabled, 
      isInputDisabled 
    } = this.state;

    return (
      <>
        <select
          value={searchCriteria}
          onChange={this.handleSearchCriteriaChange}
        >
          {
            selectOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          }
        </select>
        <input
          type='text'
          placeholder={searchPlaceholder}
          ref={this.searchStringRef}
          onChange={this.handleInputChange}
          disabled={isInputDisabled}
        />
        <button
          className='clear-button visible'
          onClick={this.handleClearSearch}
          style={{
            opacity: showSearchClear ? 0 : 1
          }}
        >
          &#x2715;
        </button>
        {
          !showSearchClear &&
          <button className='search-button'
            onClick={this.handleSearch}
            disabled={isSearchDisabled}
          >
            <i className='fa fa-search'></i>
          </button>
        }

        {
          showSearchClear &&
          <button className='clear-search-button'
            onClick={this.handleClearSearch}>
            <i className='fa fa-close'></i>
          </button>}
        {
          showSearchClear &&
          <span className='search-string'>
            Filtered by: "{searchString}"
          </span>
        }
      </>
    );
  }
}

export default Search;