import React, { Component } from 'react';
// Styles
import '../assets/stylesheets/facilityModal.css';
// Services
import { apiConfig } from '../services/apiConfig';
import { apiAuthentication } from '../services/Authenticate';
// Others
import { Button, Modal } from 'react-bootstrap';
import CustomValueModal from './CustomValueModal';

const initialState = {
  facility: {
    id: '',
    name: '',
    abbreviation: '',
    number: '',
    address: '',
    region: '',
    district: '',
    ipStart: '',
    ipEnd: '',
  },
  validationErrors: {
    name: '',
    abbreviation: '',
    number: '',
    address: '',
    region: '',
    district: '',
    ipStart: '',
    ipEnd: '',
  },
  districts: null,
  regions: null,
  showCustomRegionModal: false,
  showCustomDistrictModal: false,
  isFormValid: false,
  showErrorModal: false,
  errorModalMessage: '',
  errorModalField: '',
  showIpAssignmentModal: false,
  ipAssignment: {
    parentNumber: '',
    message: '',
  }
};

class EditFacilityModal extends Component {
  constructor(props) {
    super(props);
    const facility = this.getFacility(props.facility);
    this.state = {
      ...initialState,
      facility
    };
    let _regions, _districts;
  }

  componentDidMount() {
    this.getDistrictRegionList();
  }

  componentWillUnmount() { }

  getFacility = (facility) => {
    const {
      id,
      station_type, 
      name, 
      number, 
      region, 
      district, 
      address, 
      start_ip, 
      end_ip 
    } = facility;
    return {
      id,
      name: station_type,
      abbreviation: name,
      number,
      region,
      district,
      address,
      ipStart: start_ip,
      ipEnd: end_ip,
    };
  }

  getDistrictRegionList = () => {
    const apiUrl = apiConfig.baseUrl + apiConfig.apis.facility + '/getDistrictRegionList';
    const authHeader = apiAuthentication.getCookie().header;
    fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.status === 200) {
          const { districts, regions } = data;
          this._districts = districts;
          this._regions = regions;
          this.setState({
            districts,
            regions,
          });
        } else {
          console.error('Invalid or missing data format:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching facilities:', error);
      });
  };

  validateIPAddress = ipAddress => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ipAddress);
  };

  handleInputChange = (name, value) => {
    const { facility } = this.state;
    const validationErrors = { ...this.state.validationErrors };
    const validationRules = {
      name: {
        isInvalid: (value) => value.length > 25,
        toUpperCase: true,
        errorMessage: 'Name is too long',
        requiredMessage: 'Name is required',
      },
      abbreviation: {
        isInvalid: (value) => value.length > 4,
        toUpperCase: true,
        errorMessage: 'Abbreviation is too long',
        requiredMessage: 'Abbreviation is required',
      },
      number: {
        isInvalid: (value) => isNaN(value) || value.length > 5,
        errorMessage: 'Number is invalid',
        requiredMessage: 'Number is required',
      },
      address: {
        isInvalid: (value) => value.length > 50,
        toUpperCase: true,
        errorMessage: 'Address is too long',
        requiredMessage: 'Address is required',
      },
      ipStart: {
        isInvalid: (value) => !this.validateIPAddress(value),
        errorMessage: 'Invalid IP Address',
        requiredMessage: 'IP Address is required',
      },
      ipEnd: {
        isInvalid: (value) => !this.validateIPAddress(value),
        errorMessage: 'Invalid IP Address',
        requiredMessage: 'IP Address is required',
      },
    };
    if (validationRules.hasOwnProperty(name)) {
      const rule = validationRules[name];
      if (rule.toUpperCase) {
        value = value.toUpperCase();
      }
      validationErrors[name] = rule.isInvalid(value) ? rule.errorMessage : value.trim() === '' ? rule.requiredMessage : '';
    }
    const isFormValid = !Object.values(validationErrors).some(error => error !== '') && Object.values(facility).every(value => value !== '');
    this.setState((prevState) => ({
      facility: {
        ...prevState.facility,
        [name]: value,
      },
      validationErrors,
      isFormValid,
    }));
  };

  openCustomRegionModal = () => {
    this.setState({ showCustomRegionModal: true });
  };

  closeCustomRegionModal = () => {
    this.setState({ showCustomRegionModal: false });
  };

  openCustomDistrictModal = () => {
    this.setState({ showCustomDistrictModal: true });
  };

  closeCustomDistrictModal = () => {
    this.setState({ showCustomDistrictModal: false });
  };

  handleCustomValueDone = (value, field) => {
    value = value.toUpperCase();
    this.setState((prevState) => ({
      facility: {
        ...prevState.facility,
        [field]: value,
      },
    }));
    if (field === 'region') {
      this.setState({
        regions: [...this.state.regions, value],
        showCustomRegionModal: false
      });
      this.handleInputChange('region', value)
    } else if (field === 'district') {
      this.setState({
        districts: [...this.state.districts, value],
        showCustomDistrictModal: false
      });
      this.handleInputChange('district', value)
    }
  };

  handleCancel = () => {
    this.resetState();
    this.props.onCancelEditFacility();
  };

  handleEdit = () => {
    const { facility } = this.state;
    const { number, district, region, address } = facility;
    const payload = {
      facility_id: facility.id,
      station_type: facility.name,
      name: facility.abbreviation,
      number,
      region,
      district,
      address,
      start_ip: facility.ipStart,
      end_ip: facility.ipEnd,
    };
    this.validateFacility(payload);
  };

  resetState = () => {
    this.setState({
      facility: {},
      validationErrors: {},
      districts: this._districts,
      regions: this._regions,
      showCustomRegionModal: false,
      showCustomDistrictModal: false,
      isFormValid: false,
      showErrorModal: false,
      errorModalMessage: '',
      errorModalField: '',
      showIpAssignmentModal: false,
      ipAssignment: {}
    });
  };

  validateFacility = (payload) => {
    const apiUrl = apiConfig.baseUrl + apiConfig.apis.facility + '/edit';
    const authHeader = apiAuthentication.getCookie().header;
    fetch(apiUrl, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
      body: JSON.stringify({ ...payload, confirm_add: false }),
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.status === 200) {
          this.resetState();
          this.props.onEditFacility(payload);
        } else if (data && data.status === 206) {
          console.log('Facility validated ip already exists:', data);
          const { message, parent_number } = data;
          this.setState({
            showIpAssignmentModal: true,
            ipAssignment: {
              parentNumber: parent_number,
              message,
            }
          });
        } else {
          console.error('Invalid or missing data format:', data);
          const { message, field } = this.getErrorMessage(data);
          this.setState({
            showErrorModal: true,
            errorModalMessage: message,
            errorModalField: field,
          });
        }
      })
      .catch(error => {
        console.error('Error updating the facility:', error);
      });
  };

  getErrorMessage = ({ message, field }) => {
    if (field === undefined) {
      field = 'value';
    }
    return {
      message,
      field,
    };
  };

  handleErrorClose = () => {
    this.setState({
      showErrorModal: false,
      errorModalMessage: '',
      errorModalField: '',
    });
  };

  handleAssignmentCancel = () => {
    this.setState({
      showIpAssignmentModal: false,
      ipAssignment: {
        parentNumber: '',
        message: '',
      }
    });
  };

  handleAssignmentAccept = () => {
    const { facility } = this.state;
    const { number, district, region, address } = facility;
    const payload = {
      facility_id: facility.id,
      station_type: facility.name,
      name: facility.abbreviation,
      number,
      region,
      district,
      address,
      start_ip: facility.ipStart,
      end_ip: facility.ipEnd,
    };
    const apiUrl = apiConfig.baseUrl + apiConfig.apis.facility + '/edit';
    const authHeader = apiAuthentication.getCookie().header;
    fetch(apiUrl, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authHeader
      },
      body: JSON.stringify({ ...payload, confirm_add: true }),
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.status === 200) {
          this.resetState();
          this.props.onEditFacility(payload);
        } else {
          console.error('Invalid or missing data format:', data);
          const { message } = data;
          this.setState({
            showErrorModal: true,
            errorModalMessage: message,
          });
        }
      })
      .catch(error => {
        console.error('Error updating facility:', error);
      });
  };

  render() {
    const { show, onClose } = this.props;
    const { districts, regions, facility, validationErrors, isFormValid, ipAssignment, errorModalMessage, errorModalField, showCustomRegionModal, showCustomDistrictModal } = this.state;
    return (
      <>
        {
          show ? (<Modal size='md' centered className='facility-modal' show={show} onClose={onClose}>
            <Modal.Header>
              <Modal.Title className='uppercase'>
                Edit FAcility
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className='form-container'>
                <div className='section'>
                  <h2 className='uppercase'>Facility</h2>
                  <div className='row'>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>Facility Name</label>
                        <input
                          type='text'
                          placeholder='Enter Name'
                          value={facility.name}
                          onChange={e =>
                            this.handleInputChange('name', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.name ? 'visible' : ''}`}>{validationErrors.name}</span>
                      </div>
                    </div>
                    <div className='col col-divided'>
                      <div className='input-container input-divided'>
                        <label className='input-label'>Facility Abbr</label>
                        <input
                          type='text'
                          placeholder='Enter Abbreviation'
                          value={facility.abbreviation}
                          onChange={e =>
                            this.handleInputChange('abbreviation', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.abbreviation ? 'visible' : ''}`}>{validationErrors.abbreviation}</span>
                      </div>
                      <div className='input-container input-divided'>
                        <label className='input-label'>Facility Number</label>
                        <input
                          type='text'
                          placeholder='Enter Number'
                          value={facility.number}
                          onChange={e =>
                            this.handleInputChange('number', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.number ? 'visible' : ''}`}>{validationErrors.number}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='section'>
                  <h2 className='uppercase'>Location</h2>
                  <div className='row'>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>Address</label>
                        <input
                          className='full-width'
                          type='text'
                          placeholder='Enter Address (ex: 123 SAMPLE STREET)'
                          value={facility.address}
                          onChange={e =>
                            this.handleInputChange('address', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.address ? 'visible' : ''}`}>{validationErrors.address}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>Region</label>
                        <select
                          name='region'
                          value={facility.region}
                          onChange={e =>
                            this.handleInputChange('region', e.target.value)
                          }
                        >
                          {regions && regions.map((region, index) => <option key={index} value={region}>{region}</option>)}
                        </select>
                        <button type='button' className='custom-btn uppercase' onClick={this.openCustomRegionModal}>+ custom</button>
                        {showCustomRegionModal && <CustomValueModal
                          show={showCustomRegionModal}
                          onClose={() => this.closeCustomRegionModal()}
                          title='Add Custom Region'
                          placeholder='Enter Region Name'
                          collection={regions}
                          onDone={(value) => this.handleCustomValueDone(value, 'region')}
                        />}
                      </div>
                    </div>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>District</label>
                        <select
                          name='district'
                          value={facility.district}
                          onChange={e =>
                            this.handleInputChange('district', e.target.value)
                          }
                        >
                          {districts && districts.map((district, index) => <option key={index} value={district}>{district}</option>)}
                        </select>
                        <button type='button' className='custom-btn uppercase' onClick={this.openCustomDistrictModal}>+ CUSTOM</button>
                        {showCustomDistrictModal && <CustomValueModal
                          show={showCustomDistrictModal}
                          onClose={() => this.closeCustomDistrictModal()}
                          title='Add Custom District'
                          placeholder='Enter District Name'
                          collection={districts}
                          onDone={(value) => this.handleCustomValueDone(value, 'district')}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='section'>
                  <h2 className='uppercase'>IP Range</h2>
                  <div className='row'>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>IP Address Range - Start</label>
                        <input
                          type='text'
                          placeholder='Enter Start IP Address (ex: 0.0.0.0)'
                          value={facility.ipStart}
                          onChange={e =>
                            this.handleInputChange('ipStart', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.ipStart ? 'visible' : ''}`}>{validationErrors.ipStart}</span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='input-container input-grow'>
                        <label className='input-label'>IP Address Range - End</label>
                        <input
                          type='text'
                          placeholder='Enter End IP Address (ex: 255.0.0.1)'
                          value={facility.ipEnd}
                          onChange={e =>
                            this.handleInputChange('ipEnd', e.target.value)
                          }
                        />
                        <span className={`error-text ${validationErrors.ipEnd ? 'visible' : ''}`}>{validationErrors.ipEnd}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' className='uppercase' onClick={this.handleCancel}>Cancel</Button>
              <span></span>
              <Button variant='success' className='uppercase' onClick={this.handleEdit} disabled={!isFormValid}>Save</Button>
            </Modal.Footer>
          </Modal>) : null
        }
        {this.state.showIpAssignmentModal ? (<Modal show={this.state.showIpAssignmentModal} size='md' centered className='ip-assignment-modal'>
          <Modal.Header>
            <Modal.Title className='uppercase'>
              IP range already exists
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className='uppercase'>Facility {facility.number} will be assigned to devices at facility {ipAssignment.parentNumber}</span>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='uppercase' onClick={this.handleAssignmentCancel}>Cancel</Button>
            <span></span>
            <Button variant='success' className='uppercase' onClick={this.handleAssignmentAccept}>Assign</Button>
          </Modal.Footer>
        </Modal>) : null}
        {this.state.showErrorModal ? (<Modal show={this.state.showErrorModal} size='md' centered className='facility-error-modal'>
          <Modal.Header>
            <Modal.Title className='uppercase'>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='error-main uppercase'>{errorModalMessage}</p>
            <p className='uppercase'>Please enter a different {errorModalField}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='btn uppercase' onClick={this.handleErrorClose}>Ok</Button>
          </Modal.Footer>
        </Modal>) : null}
      </>
    );
  }
}

export default EditFacilityModal;