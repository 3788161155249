import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class CustomValueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customValue: '',
    };
  }

  handleInputChange = (e) => {
    let value = e.target.value;
    value = value.toUpperCase();
    this.setState({ customValue: value });
  };

  handleDone = () => {
    const { customValue } = this.state;
    const { onDone, collection } = this.props;

    if (customValue.trim() !== '' && !collection.includes(customValue)) {
      onDone(customValue);
      this.setState({ customValue: '' });
    }
  };

  render() {
    const { show, onClose, title, placeholder, collection } = this.props;
    const { customValue } = this.state;

    const isValueInCollection = collection.includes(customValue);
    const isDoneButtonDisabled = !customValue || customValue.length > 25 || isValueInCollection || customValue.trim() === '';

    return (
      <Modal show={show} onHide={onClose} className='custom-field-modal'>
        <Modal.Header closeButton>
          <Modal.Title className='uppercase'>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='input-container'>
            <input
              type='text'
              value={customValue}
              onChange={this.handleInputChange}
              placeholder={placeholder}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary uppercase' onClick={onClose}>
            Cancel
          </Button>
          <span></span>
          <Button
            variant='success uppercase'
            onClick={this.handleDone}
            disabled={isDoneButtonDisabled}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CustomValueModal;
