import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// Components
import Header from '../components/Header';
import SendMessage from '../components/SendMessage';
import Facilities from '../components/Facilities';
import Reports from '../components/Reports';
import Events from '../components/Events';
import NotFound from '../components/NotFound';
// Styles
import './App.css';
// Services
import { apiConfig } from '../services/apiConfig';
import { apiAuthentication } from '../services/Authenticate';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableAuth: process.env.REACT_APP_ENABLE_AUTH ? process.env.REACT_APP_ENABLE_AUTH === 'true' : true,
      loading: false
    };
  }

  componentDidMount() {
    this.handleAuthentication();
    this.setupPeriodicApiCall();
  }

  componentWillUnmount() {
    console.log('Component will Unmount');
    clearInterval(this.apiCallInterval);
  }

  handleAuthentication = () => {
    if (apiAuthentication.getCookie().header == null) {
      console.log('Token not available');
      this.clearLocalStorageAndRedirect();
    } else {
      const { enableAuth, loading } = this.state;
      if (enableAuth && !loading) {
        console.log('Token available, proceed to check time:', enableAuth, loading);
        this.getCheckTime();
      }
    }
  }

  clearLocalStorageAndRedirect() {
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('expires');
    window.localStorage.removeItem('isAdmin');
    window.location.replace(apiConfig.baseUrl + apiConfig.apis.login);
  }

  setupPeriodicApiCall() {
    this.authenticateSession();
    this.apiCallInterval = setInterval(this.authenticateSession, 10000);
  }

  setCookieExpiration() {
    const SIX_HOURS_IN_MS = 6 * 60 * 60 * 1000;
    let expires = '';
    let cookieValue = window.localStorage.getItem('jwt');
    console.log('setCookieExpiration:', cookieValue);
    if (cookieValue) {
      try {
        const date = new Date();
        date.setTime(date.getTime() + SIX_HOURS_IN_MS);
        expires = date.toUTCString();
      } catch (error) {
        console.error('Error while calculating expiration time:', error);
      }
    }
    if (expires) {
      try {
        const existingExpirationTime = window.localStorage.getItem('expires');
        if (existingExpirationTime === null) {
          window.localStorage.setItem('expires', expires);
        }
      } catch (error) {
        console.error('Error while setting "expires" in localStorage:', error);
      }
    }
    console.log('local storage expires:', window.localStorage.getItem('expires'));
  }

  authenticateSession() {
    const UTCdate = new Date().toUTCString()
    console.log('Session Expiration Time:', window.localStorage.getItem('expires'));
    console.log('Current Time:', UTCdate);
    console.log('authenticateSession:', Date.parse(window.localStorage.getItem('expires')), Date.parse(UTCdate));
    const dateA = new Date(window.localStorage.getItem('expires'));
    const dateB = new Date(UTCdate);
    const timeDifferenceInMilliseconds = dateA - dateB;
    const minutesRemaining = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
    console.log('Minutes remaining for session:', minutesRemaining);
    //Task 37605 Fix Refresh Issue
    if (Date.parse(window.localStorage.getItem('expires')) <= Date.parse(UTCdate)) {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('expires');
      window.localStorage.removeItem("isAdmin");
      window.location.replace(apiConfig.baseUrl + apiConfig.apis.login);
    }
  }

  // backend call for check the session which gives the session expiry time
  getCheckTime = () => {
    fetch(apiConfig.baseUrl + apiConfig.apis.check_session, {
      method: 'GET',
      credentials: 'include',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.authenticated) {
          window.localStorage.setItem('jwt', data.cookie);
          this.setState({ loading: true });
          this.setCookieExpiration();
          //call checkIfUserIsAdmin api function after setting up the cookie expiry
          this.checkIfUserIsAdmin();
        } else {
          window.localStorage.removeItem('expires');
          window.location.replace(apiConfig.baseUrl + apiConfig.apis.login);
        }
      })
      .catch(error => {
        console.log('Error getCheckTime:', error);
        window.location.replace(apiConfig.baseUrl + apiConfig.apis.login);
      });
  }

  // backend call to get the logged in user privilege
  checkIfUserIsAdmin = () => {
    fetch(apiConfig.baseUrl + apiConfig.apis.is_admin, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: apiAuthentication.getCookie().header,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("checkIfUserIsAdmin response:", data);
        window.localStorage.setItem("isAdmin", data.isAdmin);
        this.setState({
          loading: true
        });
      })
      .catch((error) => {
        console.log(error);
        window.localStorage.removeItem("isAdmin");
        window.location.replace(apiConfig.baseUrl + apiConfig.apis.login);
      });
  }

  render() {
    const isAdmin = window.localStorage.getItem("isAdmin");

    let routeSwitch = (
      <Switch>
        <Route exact path="/alerts" component={SendMessage} />
        <Route exact path="/facilities" component={Facilities} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/" render={() => <Redirect to="/alerts" />} />
        <Route component={NotFound} />
      </Switch>
    );

    if (isAdmin === "false") {
      routeSwitch = (
        <Switch>
          <Route exact path="/alerts" component={SendMessage} />
          <Route
            exact
            path="/facilities"
            render={() => <Redirect to="/alerts" />}
          />
          <Route
            exact
            path="/reports"
            render={() => <Redirect to="/alerts" />}
          />
          <Route
            exact
            path="/events"
            render={() => <Redirect to="/alerts" />}
          />
          <Route exact path="/" render={() => <Redirect to="/alerts" />} />
          <Route component={NotFound} />
        </Switch>
      );
    }

    return (
      <Router>
        {this.state.loading ?
          <>
            <div>
              <div className='main'>
                <Header />
                {routeSwitch}
              </div>
            </div>
          </> : null
        }
      </Router>
    );
  }
}

export default App;
