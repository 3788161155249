import React, { Component } from 'react';
// Styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/reports.css';
// Components
import Search from './Search';
// Services
import { apiConfig } from '../services/apiConfig';
import { apiAuthentication } from '../services/Authenticate';
// Others
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, ButtonGroup, Spinner } from 'react-bootstrap';

class Reports extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      title: 'REPORTING',
      loading: true,
      issues: [],
      searchResults: [],
      activeTab: '',
    };
    this.state = { ...initialState };
  }

  componentDidMount() {
    this.updateActiveTab();
  }

  componentWillUnmount() { }

  updateActiveTab() {
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('activeTab');
    if (activeTab) {
      this.setState({ activeTab });
      if (activeTab === 'registration') {
        this.fetchIssues('/helper/registrationErrors', ['noFacilityList', 'notInPhoenixList']);
      } else if (activeTab === 'connection') {
        this.fetchIssues('/helper/connectionErrors', ['connection_error_list']);
      }
    } else {
      this.setDefaultActiveTab();
    }
  }

  setDefaultActiveTab() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('activeTab', 'registration');
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    this.setState({ activeTab: 'registration' });
    this.fetchIssues('/helper/registrationErrors', ['noFacilityList', 'notInPhoenixList']);
  }

  fetchIssues(apiEndpoint, dataKeys) {
    const apiUrl = `${apiConfig.apiUrl}${apiEndpoint}`;
    const authHeader = apiAuthentication.getCookie().header;
    this.setState({ loading: true });

    fetch(apiUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.status === 200) {
          const issues = dataKeys.reduce((result, key) => {
            return result.concat(data[key]);
          }, []);
          this.setState({
            loading: false,
            issues,
            searchResults: issues,
          });
        } else {
          this.setState({
            loading: false,
            searchResults: [],
          });
          console.error('Invalid or missing data format:', data);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          searchResults: [],
        });
        console.error('Error fetching reports:', error);
      });
  }

  formatDate = (cell, row) => {
    const date = new Date(cell);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${(hours % 12) || 12}:${minutes} ${amOrPm}`;
    const formattedDate = `${month}-${day}-${year} ${formattedTime}`;
    return formattedDate;
  }

  handleTabClick(tab) {
    this.setState({ activeTab: tab });
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('activeTab', tab);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
    if (tab === 'registration') {
      this.setState({ searchResults: [] });
      this.fetchIssues('/helper/registrationErrors', ['noFacilityList', 'notInPhoenixList']);
    } else if (tab === 'connection') {
      this.setState({ searchResults: [] });
      this.fetchIssues('/helper/connectionErrors', ['connection_error_list']);
    }
  }

  handleSearch = ({ searchCriteria, searchString }) => {
    const { issues } = this.state;
    const searchResults = issues.filter(issue =>
      issue[searchCriteria]?.toLowerCase().includes(searchString.toLowerCase())
    );
    this.setState({ searchResults });
  };

  handleClearSearch = () => {
    this.setState({ searchResults: this.state.issues });
  };

  render() {
    const { activeTab } = this.state;
    let issueColumns, selectedOptions;
    if (activeTab === 'registration') {
      issueColumns = [
        {
          dataField: 'facility_name',
          text: 'Facility Abbr',
          sort: true,
          formatter: (cell, _row) => {
            return cell ? cell : 'Not Available';
          },
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'facility_num',
          text: 'Facility #',
          sort: true,
          formatter: (cell, _row) => {
            return cell ? cell : 'Not Available';
          },
        },
        {
          dataField: 'SN',
          text: 'Serial #',
          sort: true,
        },
        {
          dataField: 'model',
          text: 'Model #',
          sort: true
        },
        {
          dataField: 'app_version',
          text: 'App Version',
          sort: true,
          headerStyle: {
            width: '5%'
          },
          style: {
            width: '5%'
          }
        },
        {
          dataField: 'ip',
          text: 'IP Address',
          sort: true,
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'modified_at',
          text: 'Date/Time',
          formatter: this.formatDate,
          sort: false,
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'error',
          text: 'Error',
          sort: true,
          style: {
            color: '#aa0000'
          }
        },
      ];
    } else if (activeTab === 'connection') {
      issueColumns = [
        {
          dataField: 'facility_name',
          text: 'Facility Abbr',
          sort: true,
          formatter: (cell, _row) => {
            return cell ? cell : 'Not Available';
          },
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'facility_num',
          text: 'Facility #',
          sort: true,
          formatter: (cell, _row) => {
            return cell ? cell : 'Not Available';
          },
        },
        {
          dataField: 'SN',
          text: 'Serial #',
          sort: true,
        },
        {
          dataField: 'model',
          text: 'Model #',
          sort: true,
        },
        {
          dataField: 'app_version',
          text: 'App Version',
          sort: true,
          headerStyle: {
            width: '5%'
          },
          style: {
            width: '5%'
          }
        },
        {
          dataField: 'ip',
          text: 'IP Address',
          sort: true,
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'last_seen_time',
          text: 'Last Seen Date/Time',
          formatter: this.formatDate,
          sort: false,
          headerStyle: {
            width: '8%'
          },
          style: {
            width: '8%'
          }
        },
        {
          dataField: 'error',
          text: 'Error',
          sort: true,
          style: {
            color: '#aa0000'
          }
        },
      ];
    }
    
    if (activeTab === 'registration') {
      selectedOptions = [
        { value: 'facility_name', label: 'Facility Abbr', placeholder: 'Facility Abbr' },
        { value: 'facility_num', label: 'Facility #', placeholder: 'Facility Number' },
        { value: 'SN', label: 'Serial #', placeholder: 'Device Serial Number' },
        { value: 'model', label: 'Model #', placeholder: 'Device Model Number' },
        { value: 'app_version', label: 'App Version', placeholder: 'Device / Alert App Version' },
        { value: 'ip', label: 'IP Address', placeholder: 'Device IP Address' }
      ];
     } else if (activeTab === 'connection') {
      selectedOptions = [
        { value: 'facility_name', label: 'Facility Abbr', placeholder: 'Facility Abbr' },
        { value: 'SN', label: 'Serial #', placeholder: 'Device Serial Number' }
      ];
     }

    return (
      <div className='container-fluid d-flex flex-column Container'>
        <div className='row'>
          <div className='col-12'>
            <div className='h-100 card d-flex flex-column report-container'>
              <div className='border-bottom card-header'>
                <h5 className='m-0 report-title'>{this.state.title}</h5>
                <ButtonGroup aria-label="Issues button group" className='btn-group'>
                  <Button
                    variant="secondary"
                    className={`btn ${activeTab === 'registration' ? 'active' : ''}`}
                    onClick={() => this.handleTabClick('registration')}
                  >
                    Registration Issues
                  </Button>
                  <Button
                    variant="secondary"
                    className={`btn ${activeTab === 'connection' ? 'active' : ''}`}
                    onClick={() => this.handleTabClick('connection')}
                  >
                    Connections Issues
                  </Button>
                </ButtonGroup>
              </div>
              <div className='search-row'>
                {selectedOptions && <Search
                  defaultSearchCriteria='facility_name'
                  defaultsearchPlaceholder='Search for a Facility Abbr'
                  selectOptions={selectedOptions}
                  onSearch={this.handleSearch}
                  onClear={this.handleClearSearch}
                ></Search>}
              </div>
              <div className='table-container flex-grow-1'>
                {this.state.loading ?
                 <div className='loading'>
                 <Spinner animation="border" variant="secondary" size="sm" /> 
                 <a>Loading...</a>
                  </div> :  <div className='table-wrapper'>
                <BootstrapTable
                      keyField='udi'
                      data={this.state.searchResults}
                      columns={issueColumns}
                      bootstrap4
                      bordered={false}
                      defaultSorted={[{ dataField: 'facility_name', order: 'asc' }]}
                      headerClasses='thead-light'
                      noDataIndication='No results found'
                      // wrapperClasses='table-responsive'
                    />
                 </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
