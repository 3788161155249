import React, { Component } from 'react';
import logo from '../assets/images/logo.svg';
import '../assets/stylesheets/header.css';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  render() {

    const isAdmin = window.localStorage.getItem('isAdmin');

    let navLinks =[]
    //check if logged in user is admin show all the menu tabs else show only Send alert tab
    if(isAdmin === 'true'){
      navLinks = [
        { to: '/alerts', label: 'Send Alert' },
        { to: '/facilities', label: 'Facility Management' },
        { to: '/reports', label: 'Reporting' },
        { to: '/events', label: 'Event History' },
      ]
    }else{
      navLinks = [
        { to: '/alerts', label: 'Send Alert' }
      ];
    }

    return (
      <div className='Header'>
        <img src={logo} className='logo' alt='logo' />
        <div className='header-links-container'>
          <ul className='header-links'>
            {navLinks.map(link => (
              <li key={link.to}>
                <NavLink
                  to={link.to}
                  className='header-link'
                  activeClassName='active-link'
                >
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className='auth'></div>
      </div>
    );
  }
}

export default Header;