import React, { Component } from 'react';  
import BootstrapTable from 'react-bootstrap-table-next';
import {Collapse} from 'react-collapse';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.min.css";
import '../assets/stylesheets/table.css';
import success from '../assets/images/Success.svg';
import emergency from '../assets/images/Emergency.svg';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { apiConfig } from "../services/apiConfig";
import { apiAuthentication } from "../services/Authenticate";
import AllClear from './clearmodal.js';
import 'font-awesome/css/font-awesome.min.css';

class Bootstraptab extends Component {
    _isMounted = false;
	intervalID = 0;
	constructor(props){
		super();
		this.state = {
			devices: [],
			count: 0,
			device_count: 0,
			open: false,
			close: true,
			isLoading: false,
			collapsedData: true,
			closeData: false,
			type: '',
			columns: [{  
				dataField: 'udi',  
				text: 'Device ID',
				align: 'left',
				headerAlign: 'left',
				headerStyle: () => ({ width: '40%' })
			},  
			{  
				dataField: 'name',  
				text: 'Facility',
				align: 'left',
				headerAlign: 'left',
				headerStyle: () => ({ width: '25%' })
			}, {  
				dataField: 'modified_at',  
				text: 'Time',
				align: 'left',
				headerAlign: 'left',
				headerStyle: () => ({ width: '35%' }),
				formatter: (cell, row) => (
					moment(cell).format('YYYY-MM-DD HH:mm:ss')
				)
			}]  
		}
		this.setOpen = this.setOpen.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	// collapsed feature function for active alert red panel
	setOpen() {
		this.setState(prevState => ({
			open: !prevState.open,
			collapsedData: this.state.open
		}));
	}
	// collapsed feature on click of arrow for green panel
	handleClick() {
		this.setState(prevState => ({
			close: !prevState.close,
			closeData: this.state.close
		}));
	}

	// api call for getting devices list which has received alert
	fetchDataBasedOnNotification=(value)=>{
	   this._isMounted = true;
	   fetch(apiConfig.baseUrl + apiConfig.apis.notifications + value,{
			method: 'GET',
			credentials: 'include',
			headers: {'Content-Type': 'application/json',
							'Authorization': apiAuthentication.getCookie().header},
		})
		.then((response) => {
			this.setState({
				isLoading: true
			})
			return response.json();
		})
		.then(data => {
			if (this._isMounted) {
				if (data) {
					if (data.type === "ALL_CLEAR") {
						window.location.reload(false);
                    }
					this.setState({
						device_count: data.device_count,
						type: data.type
					});
					if (data.result.length > 0 ){
						this.setState({
							devices: data.result,
							count: data.count
						});
					} else {
						this.setState({
							devices: [],							
							count: 0
						})
					}
				}
			}
		}).catch(error => {
			console.log(error);
		});
	}
	
	componentDidMount() {
	    // initially it will run once after 10 sec
		setTimeout(
		  () => this.fetchDataBasedOnNotification(this.props.notification_id), 
		  10000
		);
		// after that it will run for each 30 sec
		this.intervalID = setInterval(async () => {
			this.fetchDataBasedOnNotification(this.props.notification_id)
		}, 30000);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notification_id !== this.props.notification_id) {
		  this.fetchDataBasedOnNotification(this.props.notification_id);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearInterval(this.intervalID);
	}

	render() {
		return (
			<>
				{this.props.succcessMessages ? 
				    <>
						<Card bg='success' style={{ width: '100%' }} className="pmd-card">
							<div class="card-header bg-success d-flex flex-row">
								<img src={success} className="alert" alt="success" />
								<div className="media-body">
									<h6 className="card-title Messages">Message Sent</h6>
									<p className="m-0 SelectedMessage">{this.props.selectedMessage}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xs-12 col-sm-6 col-md-8">
									<p className="collapseToggleText">{this.state.count}/{this.state.device_count} Devices Received the Message</p>
								</div>
								<div className="col-md-4 col-md-offset-4">
									{this.state.closeData === true ? 
										<a href="#" className="card-link" onClick={this.handleClick} aria-controls="card" aria-expanded={this.state.close} className="collapseToggleExpand"><u>Expand</u> 
											<i className="fa fa-angle-down fa-angle-down"></i></a> : <a href="#" className="card-link" onClick={this.handleClick} aria-controls="card" aria-expanded={this.state.close} className="collapseToggleExpand"><u>Collapse</u>
										<i className="fa fa-angle-up fa-angle-up"></i></a> }
								</div>
							</div>
						</Card>
						{this.state.isLoading ?
							<Collapse isOpened={this.state.close}>
							<div className="p-0 pb-3 card-body">
								<>
									<Card.Title>
										<p className="RECEIVED">RECEIVED</p>
									</Card.Title>
									<BootstrapTable
									striped
									hover
									condensed
									bordered={ false }
									scrollTop={ 'Bottom' }
									keyField='id'
									data={ this.state.devices }
									columns={ this.state.columns } >
									</BootstrapTable>
									<div className="border-top card-footer">
										<div className="row">
											<div className="text-center view-report col">
												< AllClear notification_id={this.props.notification_id} ui_class="Button-clear"/>
											</div>
										</div>
									</div>
								</>
							</div>
							</Collapse> : (<div className="container loader"><Loader
									type="ThreeDots"
									color="#412c91"
									height={80}
									width={80}
									/></div>)}
					</> :  <> 
					        <Card bg='danger' style={{ width: '100%' }} className="pmd-card">
							    { this.state.collapsedData === true ? 
								   <>
								        
										<div class="card-header bg-danger d-flex flex-row">
											<img src={emergency} class="rounded-circle align-self-start emergencyalert" />
											<div className="media-body">
												<h6 className="card-title emergencyMessage">{this.props.selectedMessage}</h6>
												<p className="card-subtitle facilityMessage">{this.props.selectedFacility}</p>
											</div>
											<small className="float-sm-right">< AllClear notification_id={this.props.notification_id} ui_class="collpaseClear"/></small>
										</div>

										<div className="row">
											<div className="text-right view-report col">
												<a href="#" className="card-link" onClick={this.setOpen} aria-controls="card" aria-expanded={this.state.open} className="collapseToggleExpand"><u>{this.state.count}/{this.state.device_count} Devices Received</u> 
													<i className="fa fa-angle-down fa-angle-down"></i>
												</a>
											</div>
										</div>
										
									</> :
									<>
										<div class="card-header bg-danger d-flex flex-row">
											<img src={emergency} className="emergencyalert" alt="emergency" />
											<div className="media-body">
												<h6 className="card-title emergencyMessageCollapsed">Emergency</h6>
												<p className="m-0 SelectedMessage">{this.props.selectedMessage}</p>
											</div>
										</div>
										<div className="row">
											<div className="col-xs-12 col-sm-12 col-md-8">
												<p className="collapseToggleText">{this.state.count}/{this.state.device_count} Devices Received the Message</p>
											</div>
										<div className="col-md-4 text-right">
												<a href="#" className="card-link" onClick={this.setOpen} aria-controls="card" aria-expanded={this.state.open} className="collapseToggleExpand"><u>Collapse</u> 
													<i className="fa fa-angle-up fa-angle-up"></i>
												</a>
											</div>
										</div>
									</>
									}
							</Card>
							<Collapse isOpened={this.state.open}>
							<div className="p-0 pb-3 card-body">
								<> 
									<Card.Title>
										<h6 className="RECEIVED">RECEIVED</h6>
									</Card.Title>
									{this.state.devices.length > 0 ? 
									   <>
											<BootstrapTable
											striped
											hover
											condensed
											bordered={ false }
											scrollTop={ 'Bottom' }
											keyField='id'
											data={ this.state.devices }
											columns={ this.state.columns } >
											</BootstrapTable>
											<div className="border-top card-footer">
												<div className="row">
													<div className="text-center view-report col">
														< AllClear notification_id={this.props.notification_id} ui_class="Button-clear"/>
													</div>
												</div>
											</div></> : <>
											<BootstrapTable striped
											hover
											condensed
											bordered={ false } keyField='id' data={ [] } columns={ this.state.columns } />
											<div className="border-top card-footer">
												<div className="row">
													<div className="text-center view-report col">
														< AllClear notification_id={this.props.notification_id} ui_class="Button-clear"/>
													</div>
												</div>
											</div>
										</>	}
								</>
							</div>
							</Collapse>
						</>
					}
			</>
			)  
	}  
}  
export default Bootstraptab