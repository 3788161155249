import React, { Component } from 'react';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/clearmodal.css';
// Services
import { apiConfig } from '../services/apiConfig';
import { apiAuthentication } from '../services/Authenticate';
// Others
import { Modal, Button } from 'react-bootstrap';
import failure from '../assets/images/Failed.svg';

class AllClear extends Component {
	state = {
		isOpen: false,
		successMessage: '',
		errorOpen: false,
		errorONClear: ''
	}

	openModal = () => this.setState({ isOpen: true });
	closeModal = () => this.setState({ isOpen: false });
	openErrorModal = () => this.setState({ errorOpen: true });
	closeErrorModal = () => this.setState({ errorOpen: false });

	updateNotificaton = () => {
		fetch(apiConfig.baseUrl + apiConfig.apis.all_clear, {
			method: 'PATCH',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': apiAuthentication.getCookie().header
			},
			body: JSON.stringify({ notification_id: this.props.notification_id, source: 'SERVER' })
		}).then(r => r.json()).then(res => {
			if (res) {
				this.closeModal();
				this.setState({ successMessage: res.message });
				window.location.reload(false);
			}
		}).catch(error => {
			this.setState({ errorONClear: 'No Network Connection Detected' });
			this.closeModal();
			this.openErrorModal();
		});
	}

	render() {
		console.log('All Clear renders');
		return (
			<>
				<Button className={this.props.ui_class} onClick={this.openModal}>ALL CLEAR</Button>
				<Modal show={this.state.isOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
					<Modal.Header >
						<Modal.Title id="contained-modal-title-vcenter">
							ARE YOU SURE?
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						THIS MESSAGE WILL BE DISMISSED.
					</Modal.Body>
					<Modal.Footer className='clear-modal-footer'>
						<Button variant="secondary" onClick={this.closeModal}>NO</Button>
						<span></span>
						<Button variant="danger" onClick={this.updateNotificaton}>YES</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.errorOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
					<Modal.Body className="errorModal">
						<img src={failure} className="failure" alt="failure" />
						<p className="errorheader">Message Failed</p>
						<p className="errorMessage">{this.state.errorONClear}</p>
					</Modal.Body>
					<Modal.Footer className='clear-modal-footer'>
						<Button variant="secondary" onClick={this.closeErrorModal}>CANCEL</Button>
						<span></span>
						<Button variant="success" onClick={this.updateNotificaton}>Retry</Button>
					</Modal.Footer>
				</Modal>
			</>
		)
	}
}
export default AllClear  