import React, { Component } from 'react';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/sendMessage.css';
// Services
import { apiConfig } from "../services/apiConfig";
import { apiAuthentication } from "../services/Authenticate";
// Others
import Bootstraptab from './table.js';
import { Modal, Button } from 'react-bootstrap';
// Assets
import failure from '../assets/images/Failed.svg';

class SendMessage extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      isOpen: false,
      errorOpen: false,
      messages: [],
      facilities: [],
      succcessMessages: '',
      selectedFacility: '',
      facility_id: '',
      message_id: '',
      selectedMessage: '',
      selectedMessageSilent: false,
      notification_id: '',
      activeAlert: false,
      errorMessage: '',
      collapsedData: true,
      spinnerLoading: false,
      facilityclassName: 'Dropdown-selection ',
      facilityList: '<option value="">Please Select One</option>'
    }
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  openErrorModal = () => this.setState({ errorOpen: true });
  closeErrorModal = () => this.setState({ errorOpen: false });

  // handle change event of facility
  handleChange(event) {
    event.preventDefault();
    var index = event.nativeEvent.target.selectedIndex;
    event.target.className = `Dropdown-selection ${event.nativeEvent.target[index].className}`;
    this.setState({ facility_id: event.target.value, selectedFacility: event.nativeEvent.target[index].text, spinnerLoading: true, message_id: '', selectedMessage: '' });
    setTimeout(() => {
      this.setState({ spinnerLoading: false })
    }, 1000);
    event.target.value ? this.fetchActiveAlert(event.target.value) : this.setState({ activeAlert: false });
  }
  // handle change event of message
  handleChangeMessage(event) {
    event.preventDefault();
    const selectedIndex = event.nativeEvent.target.selectedIndex;
    const selectedOption = event.nativeEvent.target[selectedIndex];
    const message_id = event.target.value;
    const selectedMessage = selectedOption.text;
    const silent = selectedOption.getAttribute('data-silent') === 'true';
    this.setState({ 
      message_id, 
      selectedMessage,
      selectedMessageSilent: silent,
    });
  }
  // notification creation function
  saveData = () => {
    fetch(apiConfig.baseUrl + apiConfig.apis.send_messages, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiAuthentication.getCookie().header
      },
      body: JSON.stringify({ source: 'SERVER', message_id: this.state.message_id, facility_id: this.state.facility_id })
    })
      .then((r) => {
        return r.json();
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({ 
            succcessMessages: 'Message has been Sent Successfully', 
            notification_id: res.data[0].notification_id, 
            activeAlert: true,
          })
          this.closeModal();
          this.closeErrorModal();
        } else {
          this.closeModal();
        }
      }).catch(error => {
        this.setState({ 
          errorMessage: 'No Network Connection Detected' 
        });
        this.closeModal();
        this.openErrorModal();
        console.log(error);
      });
  }
  // active alert api call for each facility

  fetchActiveAlert = (value) => {
    this._isMounted = true;
    fetch(apiConfig.baseUrl + apiConfig.apis.activeAlert + value, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiAuthentication.getCookie().header
      },
    }).then((response) => {
      return response.json();
    }).then(data => {
      if (this._isMounted) {
        if (data.status === 200) {
          if (data.result.type === 'NOTIFICATION') {
            this.setState({ 
              activeAlert: true, 
              message_id: data.result.fk_message_id, 
              selectedMessage: data.result.body, 
              selectedFacility: data.result.display, 
              notification_id: data.result.id, 
              succcessMessages: '', 
              collapsedData: true
            });
          } else {
            this.setState({ 
              activeAlert: false, 
              selectedFacility: data.result.display, 
              notification_id: data.result.id 
            });
          }
        } else {
          this.setState({ 
            activeAlert: false, 
            notification_id: '' 
          });
        }
      }
    }).catch(error => {
      this.setState({ activeAlert: false });
      console.log(error);
    })
  }

  componentDidMount() {
    this.setState({ spinnerLoading: true });
    setTimeout(() => {
      this.setState({ spinnerLoading: false });
    }, 1000);
    fetch(`${apiConfig.baseUrl}${apiConfig.apis.messages}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiAuthentication.getCookie().header
      },
    })
      .then(response => response.json())
      .then(data => {
        const messages = data.data.map(({ id, body, silent }) => ({
          value: id,
          label: `${body}${ silent ? ' (SILENCED)' : '' }`,
          silent,
        }));
        this.setState({
          messages: [{ value: '', label: 'Please Select One' }, ...messages]
        });
      }).catch(error => {
        console.log(error);
      });

    // facility API call
    fetch(apiConfig.baseUrl + apiConfig.apis.facility, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': apiAuthentication.getCookie().header
      },
    })
      .then(response => response.json())
      .then(data => {
        var loopData = ''
        var appendvalue = this.state.facilityclassName;
        loopData += `<option class="black" value="">Please Select One</option>`;
        let facilityFromApi = data.data.map(facility => {
          // if (facility.parent_id != null){
          //         loopData += `<option class="red" value=${facility.id}>${facility.info}</option>`;
          // }else{
          // 	loopData += `<option class="black" value=${facility.id}>${facility.info}</option>`;
          // }
          loopData += `<option class="black" value=${facility.id}>${facility.info}</option>`;
          if (facility.default === true) {
            if (facility.parent_id != null) {
              appendvalue += "red";
            }
            this.setState({ 
              facility_id: facility.id, 
              selectedFacility: facility.info, 
              facilityclassName: appendvalue 
            });
            return { value: facility.id, display: facility.info }
          }
          else {
            return { value: facility.id, display: facility.info }
          }
        });

        this.setState({ facilityList: loopData });
        this.setState({
          facilities: [{ value: '', display: 'Please Select One' }].concat(facilityFromApi)
        });
        // make active alert api call for each facility
        if (this.state.facility_id) {
          this.fetchActiveAlert(this.state.facility_id);

          // if active alert is not there , will make api call
          this.intervalID = setInterval(async () => {
            console.log(this.state.activeAlert);
            if (this.state.activeAlert === false) {
              this.fetchActiveAlert(this.state.facility_id)
            }
          }, 30000);
        }
      }).catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.intervalID);
  }

  render() {
    const { facilityList } = this.state
    return (
      <div className="container-fluid d-flex flex-column Rectangle">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8">
            <div className="h-100 card card-small">
              <div className="border-bottom card-header sendMessage">
                <h6 className="m-0 SEND-ALERT-MESSAGE">SEND ALERT MESSAGE</h6>
              </div>
              <ul className="list-group list-group-flush">
                <li className="px-3 list-group-item">
                  <strong className="text-muted d-block mb-2 FACILITY">FACILITY</strong>
                  <div className="custom-file mb-3">
                    <select 
                      className={this.state.facilityclassName} 
                      value={this.state.facility_id} 
                      onChange={this.handleChange.bind(this)} 
                      dangerouslySetInnerHTML={{ __html: facilityList }} >
                    </select>
                  </div>
                  <strong className="text-muted d-block mb-2 SELECT-ALERT-MESSAGE">SELECT ALERT MESSAGE</strong>
                  <div className="custom-file mb-3">
                    <select className="Dropdown-selection" 
                      value={this.state.message_id} 
                      onChange={this.handleChangeMessage.bind(this)}
                      disabled={this.state.activeAlert === true || (this.state.spinnerLoading) || (!this.state.facility_id)}>
                      { this.state.messages && 
                        this.state.messages.map((message, index) => (
                          <option key={index} value={message.value} data-silent={message.silent}>{message.label}</option>
                        ))}
                    </select>
                  </div>
                </li>
              </ul>
              <div className="border-top card-footer">
                <div className="row footer">
                  <div className="text-center view-report col">
                    <Button id="notification" className="notification-btn" disabled={this.state.activeAlert === true || (!this.state.message_id)} onClick={this.openModal}>SEND MESSAGE</Button>
                  </div>
                </div>
              </div>
              <Modal show={this.state.isOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" className='message-modal' centered >
                <Modal.Header >
                  <Modal.Title id="contained-modal-title-vcenter">
                    ARE YOU SURE?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>THE MESSAGE WILL BE SENT TO SELECTED {this.state.selectedFacility}.</p>
                  { this.state.selectedMessageSilent && <p>(THIS WILL BE A SILENT ALERT)</p> }
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='secondary uppercase' onClick={this.closeModal}>Cancel</Button>
                  <span></span>
                  <Button variant='success uppercase' onClick={this.saveData}>Send</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <div className="h-100 card card-small">
              <div className="border-bottom card-header sendMessage">
                <h5 className="m-0 text-center ALERT-STATUS">ALERT STATUS</h5>
              </div>
              {this.state.activeAlert === true ? (<Bootstraptab succcessMessages={this.state.succcessMessages} selectedMessage={this.state.selectedMessage} selectedFacility={this.state.selectedFacility} notification_id={this.state.notification_id} collapsedData={this.state.collapsedData} />) :
                (<>
                  <hr />
                  <ul className="list-group list-group-flush nomessage">
                    <li className="px-3 list-group-item">
                      <div className="custom-file mb-3">
                        <strong className="text-muted d-block mb-2 text-center">NO MESSAGE SENT</strong>
                        <strong className="text-muted d-block mb-2 text-center NO-MESSAGE-SENT">Sent Messages will be displayed here</strong>
                      </div>
                    </li>
                  </ul>
                  <div className="border-top card-footer">
                    <div className="row footer">
                      <div className="text-center view-report col">
                        <Button className="notification-btn" disabled={!this.state.activeAlert}>ALL CLEAR</Button>
                      </div>
                    </div>
                  </div>
                </>)
              }
            </div>
          </div>
          <Modal show={this.state.errorOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Body className="errorModal">
              <img src={failure} className="failure" alt="failure" />
              <p className="errorheader">Message Failed</p>
              <p className="errorMessage">{this.state.errorMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="CANCEL" onClick={this.closeErrorModal}>CANCEL</Button>
              <Button variant="success" className="SEND" onClick={this.saveData}>Retry</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export default SendMessage;