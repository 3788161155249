import React, { Component } from 'react';
// Styles
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/events.css';
// Components
import Search from './Search';
// Services
import { apiAuthentication } from '../services/Authenticate';
import { getEvents } from '../services/eventService';
// Others
import BootstrapTable from 'react-bootstrap-table-next';
import { Spinner } from 'react-bootstrap';

class Events extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      title: 'EVENT HISTORY (90 Days)',
      loading: true,
      events: [],
      searchResults: [],
    };
    this.state = { ...initialState };
  }

  componentDidMount() {
    const authHeader = apiAuthentication.getCookie().header;
    getEvents(authHeader)
      .then(data => {
        this.setState({
          loading: false,
          events: data,
          searchResults: data,
        });
      })
      .catch(error => {
        console.error('Error fetching events [Component]:', error);
      });
  }

  componentWillUnmount() {}

  formatDate = (cell, _row) => {
    const date = new Date(cell);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${(hours % 12) || 12}:${minutes} ${amOrPm}`;
    const formattedDate = `${month}-${day}-${year} ${formattedTime}`;
    return formattedDate;
  }

  handleSearch = ({ searchCriteria, searchString }) => {
    const { events } = this.state;
    searchString = searchString.toString().toLowerCase();

    if (searchCriteria === 'status_id') {
      if (searchString === 'active') {
        searchString = 2;
      } else if (searchString === 'cleared') {
        searchString = 4;
      }
    }

    const searchResults = events.filter(event =>
      event[searchCriteria]?.toString().toLowerCase().includes(searchString)
    );

    this.setState({ searchResults });
  };

  handleClearSearch = () => {
    this.setState({ searchResults: this.state.events });
  };

  render() {
    const eventColumns = [
      {
        dataField: 'dest_facility',
        text: 'Receiving Facility',
        sort: true,
        formatter: (cell, _row) => {
          return cell ? cell : 'Not Available';
        },
      },
      {
        dataField: 'comatstation',
        text: 'ComatStation Facility',
        sort: true,
        formatter: (cell, _row) => {
          return cell ? cell : 'Not Available';
        },
      },
      {
        dataField: 'emp_name',
        text: 'Employee Name',
        sort: true,
        formatter: (cell, _row) => {
          return cell ? cell : 'Not Available';
        },
      },
      {
        dataField: 'emp_number',
        text: 'Empl ID',
        sort: true,
        headerStyle: {
          width: '7%'
        },
        formatter: (cell, _row) => {
          return cell ? cell : 'Not Available';
        },
      },
      {
        dataField: 'alert_message',
        text: 'Alert',
        sort: true,
        headerStyle: {
          width: '20%'
        },
        formatter: (cell, _row) => {
          return cell ? cell : 'Not Available';
        },
      },
      {
        dataField: 'status_id',
        text: 'Status',
        sort: true,
        headerStyle: {
          width: '6%'
        },
        formatter: (cell, _row) => {
          let status;
          if (cell == 2) status = 'Active';
          if (cell == 4) status = 'Cleared';
          return status ? status : 'Not Available';
        },
      },
      {
        dataField: 'devices fraction',
        text: 'Devices Received',
        headerStyle: {
          width: '8%'
        },
        formatter: (_cell, row) => {
          const {devices_received, total_devices} = row;
          const received = devices_received !== null ? devices_received : 0;
          const total = total_devices !== null ? total_devices : 0;
          return `${received}/${total}`;
        }
      },
      {
        dataField: 'created_at',
        text: 'Date/Time',
        formatter: this.formatDate,
        sort: false,
        headerStyle: {
          width: '10%'
        },
        style: {
          width: '8%'
        }
      },
    ];
    const selectedOptions = [
      // { value: '', label: 'Origin Facility', placeholder: 'Origin Facility'},
      { value: 'dest_facility', label: 'Receiving Facility', placeholder: 'Receiving Facility'},
      { value: 'emp_name', label: 'Employee Name', placeholder: 'Employee Name'},
      { value: 'emp_number', label: 'Employee ID', placeholder: 'Employee ID'},
      { value: 'alert_message', label: 'Alert', placeholder: 'Alert'},
      { value: 'status_id', label: 'Status', placeholder: 'Status'}
    ];
    
    return (
      <div className='container-fluid d-flex flex-column Container'>
        <div className='row'>
          <div className='col-12'>
            <div className='h-100 card d-flex flex-column event-container'>
              <div className='border-bottom card-header'>
                <h5 className='m-0 event-title'>{this.state.title}</h5>
              </div>
              <div className='search-row'>
                <Search
                  defaultSearchCriteria='dest_facility'
                  defaultsearchPlaceholder='Search for a Receiving Facility'
                  selectOptions={selectedOptions}
                  onSearch={this.handleSearch}
                  onClear={this.handleClearSearch}
                ></Search>
              </div>
              <div className='table-container flex-grow-1'>
                {this.state.loading ?
                  <div className='loading'>
                  <Spinner animation="border" variant="secondary" size="sm" /> 
                  <a>Loading...</a>
                   </div> : <div className='table-wrapper'>
                    <BootstrapTable
                      keyField='id'
                      data={this.state.searchResults}
                      columns={eventColumns}
                      bootstrap4
                      bordered={false}
                      defaultSorted={[{ dataField: 'type', order: 'desc' }]}
                      headerClasses='thead-light'
                      noDataIndication='No results found'
                      // wrapperClasses='table-responsive'
                    />
                  </div> 
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
