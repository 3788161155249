export const apiAuthentication = {
  getCookie: function() {
    const name = window.localStorage.getItem('jwt') || '_alert_app_session=';
    const decodedCookie = decodeURIComponent(name);
    const cookies = decodedCookie.split(';');
    const cookieValue = cookies[0].substring(19);
    const authorization = cookieValue ? 'Bearer ' + cookieValue : ""
    if (!name) {
      return null;
    }
    return {
      header: authorization
    };
  }
};
