import React from 'react';
import { withRouter } from 'react-router-dom';
// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/notFound.css';
// Others
import { Button } from 'react-bootstrap';

const NotFound = (props) => {
  const redirectToHome = () => {
    props.history.push('/alerts');
  };

  return (
    <div className='container-fluid d-flex flex-column Container'>
      <div className='row'>
        <div className='col-12'>
          <div className='h-100 card'>
            <div className='text-center my-auto not-found-container'>
              <h2>404</h2>
              <h3>Page Not Found</h3>
              <p>The page you are looking for does not exist.</p>
              <Button className='not-found-btn col-2' onClick={redirectToHome}>GO BACK HOME</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
