const prefix = 'alerts/v1';

export const apiConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
  apiUrl: `${process.env.REACT_APP_API_URL}${prefix}`,
  apis: {
    login: 'login',
    facility: `${prefix}/facility`,
    messages: `${prefix}/messages`,
    send_messages: `${prefix}/notification`,
    notifications: `${prefix}/registration/facility?notification_id=`,
    all_clear: `${prefix}/notification/clear`,
    check_session: `${prefix}/check_timer`,
    activeAlert: `${prefix}/notification?facility_id=`,
    is_admin: `${prefix}/helper/isAdmin`
  }
};
