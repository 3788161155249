// Services
import { apiConfig } from './apiConfig';

export const getEvents = (authHeader) => {
  const apiUrl = `${apiConfig.apiUrl}/notification/history`;
  return fetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authHeader
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data && data.status === 200 && data.data) {
        return data.data;
      } else {
        console.error('Invalid or missing data format:', data);
        return [];
      }
    })
    .catch(error => {
      console.error('Error fetching events [Service]:', error);
      return [];
    });
};